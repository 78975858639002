import React, { forwardRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import InputAdornment from "@material-ui/core/InputAdornment";
import Link from "@material-ui/core/Link";
import Slide from "@material-ui/core/Slide";
import TextField from "@material-ui/core/TextField";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import ChevronLeftRoundedIcon from "@material-ui/icons/ChevronLeftRounded";

import {
  openSelector,
  toggleLoginDialog,
  toggleRegisterDialog,
  toggleResetPasswordDialog,
  toggleSnackbar
} from "Slices/openSlice";
import { loginUser, userSelector, clearErrors } from "Slices/userSlice";
import { setSnackbarMessage } from "Slices/contentSlice";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(5, 10),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(4, 2)
    }
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: 36,
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      marginTop: 0
    }
  },
  textField: {
    margin: theme.spacing(1, 0),
    "& input": {
      fontSize: 14
    }
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  button: {
    margin: theme.spacing(3, 0),
    fontSize: 18,
    fontWeight: "bold",
    color: theme.palette.primary.main,
    textTransform: "none"
  },
  paragraph: {
    textAlign: "center",
    "&:last-child": {
      marginBottom: 0
    }
  },
  link: {
    fontSize: 14,
    textAlign: "center",
    cursor: "pointer",
    textDecoration: "underline"
  },
  backButton: {
    position: "absolute",
    top: 0,
    left: 0,
    lineHeight: 1,
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  backIcon: {
    fontSize: "40px !important"
  },
  error: {
    textAlign: "center",
    margin: theme.spacing(1, 0, 0),
    fontSize: 12,
    color: theme.palette.error.main
  }
}));

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const LoginDialog = () => {
  const classes = useStyles();
  const { register, handleSubmit, reset } = useForm();
  const { loginDialog } = useSelector(openSelector);
  const { errors } = useSelector(userSelector);
  const dispatch = useDispatch();

  const onSubmit = data => {
    dispatch(loginUser(data)).then(res => {
      if (!res.error) {
        handleClose();

        dispatch(setSnackbarMessage("Sign in successful!"));
        dispatch(toggleSnackbar());
      }
    });
  };

  const handleClick = type => {
    handleClose();

    const timeout = setTimeout(() => {
      if (type === "register") {
        dispatch(toggleRegisterDialog());
      } else {
        dispatch(toggleResetPasswordDialog());
      }
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  };

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }

    dispatch(toggleLoginDialog());
    dispatch(clearErrors());
    reset();
  };

  return (
    <Dialog
      open={loginDialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth
      onClose={handleClose}
    >
      <div className={classes.root}>
        <Button
          className={classes.backButton}
          startIcon={<ChevronLeftRoundedIcon className={classes.backIcon} />}
          color="primary"
          disableRipple
          onClick={handleClose}
        >
          Back
        </Button>

        <h1 className={classes.title}>Sign in</h1>

        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <TextField
            type="text"
            placeholder="Enter email"
            fullWidth
            color="primary"
            error={errors?.hasOwnProperty("email")}
            helperText={errors?.email}
            className={classes.textField}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailOutlinedIcon className={classes.icon} />
                </InputAdornment>
              )
            }}
            {...register("email")}
          />

          <TextField
            type="password"
            placeholder="Enter password"
            fullWidth
            color="primary"
            error={errors?.hasOwnProperty("password")}
            helperText={errors?.password}
            className={classes.textField}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockOutlinedIcon className={classes.icon} />
                </InputAdornment>
              )
            }}
            {...register("password")}
          />

          {errors?.credentials && (
            <p className={classes.error}>{errors?.credentials}</p>
          )}

          <Button
            variant="outlined"
            type="submit"
            fullWidth
            className={classes.button}
          >
            Sign In
          </Button>

          <p className={classes.paragraph}>
            <Link
              onClick={() => handleClick("register")}
              className={classes.link}
            >
              New user? Create an account
            </Link>
          </p>

          <p className={classes.paragraph}>
            <Link
              onClick={() => handleClick("password")}
              className={classes.link}
            >
              Forgot password?
            </Link>
          </p>
        </form>
      </div>
    </Dialog>
  );
};

export default LoginDialog;
