import React from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import AppBar from "@material-ui/core/AppBar";
import Hidden from "@material-ui/core/Hidden";
import Toolbar from "@material-ui/core/Toolbar";

import Navbar from "./Navbar";
import AvatarButton from "./buttons/AvatarButton";
import LoginButton from "./buttons/LoginButton";
import MenuButton from "./buttons/MenuButton";
import PlaylistButton from "./buttons/PlaylistButton";

import { userSelector } from "Slices/userSlice";

const Header = () => {
  const { pathname } = useLocation();
  const { user } = useSelector(userSelector);

  const isHome = pathname === "/" || pathname === "/2020";
  const is2020 = pathname === "/2020";

  return (
    <AppBar position="sticky" color="inherit">
      <Toolbar>
        <MenuButton />
        <Navbar />

        {isHome && (
          <>
            <Hidden smDown>{user ? <AvatarButton /> : <LoginButton />}</Hidden>
            {user && is2020 && <PlaylistButton />}
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
