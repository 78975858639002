import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import ImageList from "@material-ui/core/ImageList";
import ImageListItem from "@material-ui/core/ImageListItem";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { setCurrentVideo } from "Slices/contentSlice";
import { toggleLoginDialog } from "Slices/openSlice";
import { userSelector } from "Slices/userSlice";

const useStyles = makeStyles(theme => ({
  gridContainer: {
    marginBottom: theme.spacing(10),
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(5)
    }
  },
  imageItem: {
    overflow: "visible"
  },
  image: {
    objectFit: "cover",
    width: "100%",
    height: "100%",
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[4]
  },
  video: {
    objectFit: "cover",
    backgroundColor: theme.palette.common.black,
    width: "100%",
    height: "100%",
    cursor: "pointer",
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[4]
  },
  button: {
    position: "absolute",
    top: theme.spacing(2),
    right: theme.spacing(2)
  }
}));

const GridContainer = ({ item, variant }) => {
  const classes = useStyles();
  const xs = useMediaQuery(theme => theme.breakpoints.down("xs"));
  const dispatch = useDispatch();
  const { token } = useSelector(userSelector);

  const { images, heading, description, thumbnail, _id } = item;

  const handleClick = () => {
    dispatch(setCurrentVideo(item));
    window.scrollTo(0, 0);

    if (!token) {
      dispatch(toggleLoginDialog());
    }
  };

  if (variant === "right") {
    return (
      <Grid container spacing={2} className={classes.gridContainer}>
        <Grid item xs={12} lg={6}>
          <Typography variant="body1" color="primary" paragraph>
            <strong>{heading}</strong>
          </Typography>

          <Typography variant="body1" color="primary">
            {description}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={4} lg={2}>
          <ImageList
            rowHeight={"auto"}
            className={classes.imageList}
            cols={xs ? 2 : 1}
            gap={16}
          >
            {images.map(image => (
              <ImageListItem key={image} classes={{ item: classes.imageItem }}>
                <img
                  src={image}
                  alt={heading}
                  title={heading}
                  className={classes.image}
                />
              </ImageListItem>
            ))}
          </ImageList>
        </Grid>

        <Grid item xs={12} sm={8} lg={4}>
          <div className="video-container" onClick={handleClick}>
            <img src={thumbnail} alt={heading} className={classes.video} />
            <img src="/play.svg" alt="Play" className="play-button" />
          </div>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={2} className={classes.gridContainer}>
      <Grid item xs={12} sm={4} lg={2}>
        <ImageList
          rowHeight={"auto"}
          className={classes.imageList}
          cols={xs ? 2 : 1}
          gap={16}
        >
          {images.map(image => (
            <ImageListItem key={image} classes={{ item: classes.imageItem }}>
              <img
                src={image}
                alt={heading}
                title={heading}
                className={classes.image}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Grid>

      <Grid item xs={12} sm={8} lg={4}>
        <div className="video-container" onClick={handleClick}>
          <img src={thumbnail} alt={heading} className={classes.video} />
          <img src="/play.svg" alt="Play" className="play-button" />
        </div>
      </Grid>

      <Grid item xs={12} lg={6}>
        <Typography variant="body1" color="primary" paragraph>
          <strong>{heading}</strong>
        </Typography>

        <Typography variant="body1" color="primary">
          {description}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default GridContainer;
