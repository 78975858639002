import React from "react";
import { useSelector } from "react-redux";

import Layout from "./Layout";
import Playlist from "./Playlist";
import Toast from "./Toast";
import LoginDialog from "./dialogs/LoginDialog";
import DownloadDocumentsDialog from "./dialogs/DownloadDocumentsDialog";
import EditProfileDialog from "./dialogs/EditProfileDialog";
import ResetPasswordDialog from "./dialogs/ResetPasswordDialog";
import RegisterDialog from "./dialogs/RegisterDialog";

import { userSelector } from "Slices/userSlice";
import { openSelector } from "Slices/openSlice";

const HomeLayout = ({ children }) => {
  const { user } = useSelector(userSelector);
  const { playlistDrawer } = useSelector(openSelector);

  return (
    <Layout>
      {user ? (
        <>
          {playlistDrawer && <Playlist />}
          <EditProfileDialog />
          <DownloadDocumentsDialog />
        </>
      ) : (
        <>
          <LoginDialog />
          <RegisterDialog />
          <ResetPasswordDialog />
        </>
      )}
      {children}
      <Toast />
    </Layout>
  );
};

export default HomeLayout;
