const api = {
  login: "/auth/user",
  register: "/user",
  resetPassword: "/user/reset-password",
  editPassword: "/user/password",
  company: "/company",
  category: "/category",
  playlist: "/playlist",
  download: "/download",
  video: "/video",
  content2021: "/content"
};

export default api;
