import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";

import AddToQueueIcon from "@material-ui/icons/AddToQueue";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import LanguageIcon from "@material-ui/icons/Language";
import EmailIcon from "@material-ui/icons/Email";
import RemoveFromQueueIcon from "@material-ui/icons/RemoveFromQueue";

import api from "Api/wavemaker.api";

import useFetch from "Hooks/useFetch";
import { userSelector } from "Slices/userSlice";
import {
  addToPlaylist,
  incrementViewCounter,
  playlistSelector,
  removeFromPlaylist,
  setCurrentlyPlayingId,
  setDocuments
} from "Slices/playlistSlice";
import {
  toggleDownloadDocumentsDialog,
  toggleSnackbar
} from "Slices/openSlice";
import { setSnackbarMessage } from "Slices/contentSlice";

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.common.white,
    objectFit: "contain",
    backgroundSize: "cover",
    backgroundPosition: "center top",
    position: "relative",
    margin: theme.spacing(4, 8, 0),
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(2, 2, 0)
    }
  },
  video: {
    backgroundColor: "#000",
    maxHeight: "75vh",
    width: "100%"
  },
  videoContainer: {
    "&:hover": {
      "& $skipBtn": {
        visibility: "visible",
        opacity: 1
      }
    }
  },
  content: {
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(7),
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(4)
    }
  },
  companyName: {
    fontSize: 40,
    textTransform: "uppercase",
    [theme.breakpoints.down("sm")]: {
      fontSize: 32
    }
  },
  description: {
    fontSize: 20,
    [theme.breakpoints.down("sm")]: {
      fontSize: 16
    }
  },
  actionBtn: {
    margin: theme.spacing(0, 1, 1, 0),
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(0, 0, 1, 0),
      width: "100%"
    }
  },
  mailLink: {
    color: theme.palette.primary.main,
    marginTop: theme.spacing(3),
    display: "inline-block"
  },
  skipBtn: {
    position: "absolute",
    opacity: 0,
    visibility: "hidden",
    top: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 1,
    color: theme.palette.common.white,
    border: `1px solid ${theme.palette.common.white}`,
    transition: "opacity 0.5s"
  },
  flexbox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap"
  },
  views: {
    fontSize: 20,
    color: theme.palette.grey[800],
    marginTop: 0,
    [theme.breakpoints.down("sm")]: {
      fontSize: 14
    }
  }
}));

const Banner = ({ firstFeatured }) => {
  const classes = useStyles();
  const videoEl = useRef(null);
  const { token } = useSelector(userSelector);
  const { playlist, queue } = useSelector(playlistSelector);
  const dispatch = useDispatch();
  const [featured, setFeatured] = useState({});
  const [prevFeatured, setPrevFeatured] = useState({});
  const [index, setIndex] = useState(0);
  const [src, setSrc] = useState("");
  const [thumbnail, setThumbnail] = useState("");

  const existsInPlaylist = playlist.some(x => x._id === featured?._id);
  const hasNext = index < queue.length - 1;

  useEffect(() => {
    videoEl.current.controls = token ? true : false;
  }, [token]);

  useEffect(() => {
    setFeatured(firstFeatured);
    setSrc(firstFeatured?.videoUrl.url);
    setThumbnail(firstFeatured?.thumbnail)
  }, [firstFeatured]);

  useEffect(() => {
    if (queue.length > 0) {
      const timeout = setTimeout(function () {
        videoEl.current.play();
      }, 150);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [src]);

  useEffect(() => {
    if (queue.length > 0) {
      setIndex(0);
      setSrc(queue[0].videoUrl.url);
      setFeatured(queue[0]);
    }
  }, [queue]);

  const handleClick = entry => {
    if (existsInPlaylist) {
      dispatch(removeFromPlaylist(entry._id))
        .then(({ error, payload }) => {
          if (!error) {
            dispatch(setSnackbarMessage(payload?.message));
            dispatch(toggleSnackbar());
          } else {
            console.log(error);
          }
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      dispatch(addToPlaylist(entry))
        .then(({ error, payload }) => {
          if (!error) {
            dispatch(setSnackbarMessage(payload?.message));
            dispatch(toggleSnackbar());
          } else {
            console.log(error);
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  };

  const handleEnded = () => {
    if (hasNext) {
      let nextIndex = index + 1;
      setIndex(nextIndex);
      setSrc(queue[nextIndex].videoUrl.url);
      setFeatured(queue[nextIndex]);
    }
  };

  const handlePlay = () => {
    if (prevFeatured !== featured) {
      dispatch(incrementViewCounter(featured?._id));
      dispatch(setCurrentlyPlayingId(featured?._id));
      setPrevFeatured(featured);
    }
  };

  const handleDownload = () => {
    const docs = featured?.downloads.map(item => ({
      _id: featured?._id,
      companyName: featured?.companyName,
      url: item.url,
      public_id: item.public_id
    }));

    dispatch(setDocuments(docs));
    dispatch(toggleDownloadDocumentsDialog());
  };

  return (
    <div id="banner" className={classes.root}>
      <div className={classes.videoContainer}>
        <video
          className={classes.video}
          ref={videoEl}
          src={src}
          poster={thumbnail}
          onPlay={handlePlay}
          onEnded={handleEnded}
          controlsList="nodownload"
        />

        {token && hasNext && (
          <Button
            className={classes.skipBtn}
            variant="outlined"
            color="default"
            onClick={handleEnded}
          >
            Skip Video
          </Button>
        )}
      </div>

      <div className={classes.content}>
        <div className={classes.flexbox}>
          <h1 className={classes.companyName}>{featured?.companyName}</h1>
          <p className={classes.views}>{featured?.views} views</p>
        </div>

        {featured?.tags?.map(tag => (
          <Chip
            className={classes.chip}
            key={tag}
            label={tag}
            color="secondary"
          />
        ))}

        <p className={classes.description}>{featured?.description}</p>

        {token && (
          <>
            <div>
              <Button
                className={classes.actionBtn}
                color="primary"
                variant="contained"
                startIcon={
                  existsInPlaylist ? (
                    <RemoveFromQueueIcon />
                  ) : (
                    <AddToQueueIcon />
                  )
                }
                onClick={() => handleClick(featured)}
              >
                {existsInPlaylist ? "Remove from Playlist" : "Add to Playlist"}
              </Button>

              {featured?.downloads?.length > 0 && (
                <Button
                  className={classes.actionBtn}
                  color="primary"
                  variant="outlined"
                  startIcon={<FileCopyIcon />}
                  onClick={handleDownload}
                >
                  Documents
                </Button>
              )}

              <Button
                className={classes.actionBtn}
                color="primary"
                variant="outlined"
                startIcon={<LanguageIcon />}
                onClick={() => window.open(featured.url, "_blank")}
              >
                Website
              </Button>

              <Button
              className={classes.actionBtn}
              color="primary"
              variant="outlined"
              startIcon={<EmailIcon />}
              onClick={() => window.open(featured?.email?.includes("@")
              ? "mailto:" + featured?.email
              : featured?.email, "_blank")}
            >
              Email
            </Button>
            </div>

            {/* <a
              className={classes.mailLink}
              href={
                featured?.email?.includes("@")
                  ? "mailto:" + featured?.email
                  : featured?.email
              }
              target="_blank"
            >
              {featured?.email}
            </a> */}
          </>
        )}
      </div>
    </div>
  );
};

export default Banner;
