import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";

import api from "Api/wavemaker.api";
import useFetch from "Hooks/useFetch";

import Footer from "Components/Footer";
import HomeLayout from "Components/HomeLayout";
import Sidebar from "Components/2021/Sidebar";
import VideoPlayer from "Components/2021/VideoPlayer";
import BaseSectionLayout from "Components/2021/BaseSectionLayout";
import TheNextWave from "Components/2021/TheNextWave";
import Head from "Components/Head";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  main: {
    width: "calc(100vw - 100px)",
    maxWidth: 1600,
    margin: "auto",
    position: "relative",
    left: 100,
    paddingRight: 100,
    [theme.breakpoints.down("sm")]: {
      width: "100vw",
      left: "auto",
      padding: theme.spacing(2)
    }
  },
  divider: {
    backgroundColor: theme.palette.primary.main,
    margin: theme.spacing(10, "auto"),
    width: 350
  }
}));

const Page2021 = () => {
  const classes = useStyles();

  const { data, loading, error } = useFetch(api.content2021);
  const [content2021, setContent2021] = useState([]);

  useEffect(() => {
    setContent2021(data);
  }, [data]);

  if (content2021.length === 0) return null;

  return (
    <HomeLayout>
      <Head title="2021"/>

      <div className={classes.root}>
        <Sidebar />

        <main className={classes.main}>
          <VideoPlayer />

          <BaseSectionLayout
            title="Staying Ahead of the Curve"
            data={content2021.entry.slice(0, 2)}
            variant="right"
          />

          <BaseSectionLayout
            title="Wavemaker Spotlight"
            data={content2021.entry.slice(2, 5)}
            variant="left"
          />

          <TheNextWave item={content2021.entry[5]} />
        </main>
      </div>

      <Footer year="2024" />
    </HomeLayout>
  );
};

export default Page2021;
