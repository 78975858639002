import { useState, useEffect } from "react";
import axios from "axios";

import instance from "../api/wavemaker.axios";

const useFetch = (api) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const source = axios.CancelToken.source();
    instance
      .get(api, { cancelToken: source.token })
      .then((res) => {
        setLoading(false);
        setData(res.data);
      })
      .catch((err) => {
        setLoading(false);
        setError(err.message);
        console.error(error);
      });

    return () => {
      source.cancel();
    };
  }, [api]);
  
  return { data, loading, error };
};

export default useFetch;
