import React from "react";

import Typography from "@material-ui/core/Typography";

import GridContainer from "./GridContainer";
import Line from "./Line";

const BaseSectionLayout = ({ title, data, variant }) => (
  <section id={title.toLowerCase().split(" ").join("-")}>
    <Typography variant="h5" color="primary" className="title">
      {title.toUpperCase()}
    </Typography>

    {data.map(item => (
      <GridContainer key={item.heading} item={item} variant={variant} />
    ))}

    <Line />
  </section>
);

export default BaseSectionLayout;
