export const content2021 = [
  {
    _id: "61b0c9929060647f9162a378",
    images: [
      "https://res.cloudinary.com/startsomething-com-sg/image/upload/v1638193771/wavemaker/Foodmap-photo-12_v0m6yz.jpg",
      "https://res.cloudinary.com/startsomething-com-sg/image/upload/v1638193774/wavemaker/MindX-photo-4-tungdemo_jvdsx6.jpg"
    ],
    thumbnail: 
      "https://res.cloudinary.com/startsomething-com-sg/image/upload/v1639018604/wavemaker/vietnam_thumbnail_qysvpw.jpg",
    video:
      "https://res.cloudinary.com/startsomething-com-sg/video/upload/v1640068072/wavemaker/vn_production_sia8wg.mp4",
    heading: "Vietnam",
    description:
      "With its sizable, young, and tech-savvy population, Vietnam is an attractive market for tech entrepreneurs and investors alike. Three unicorns have emerged from the country so far, and two more are in the running for the billion-dollar status—all in hot industries like e-commerce, fintech, and gaming. However, in Vietnam, there remains to be many massive but less obvious opportunities."
  },
  {
    _id: "61b0f43a9060647f9162a379",
    images: [
      "https://res.cloudinary.com/startsomething-com-sg/image/upload/v1638198306/wavemaker/Aiah-photo-10_nlvqow.png",
      "https://res.cloudinary.com/startsomething-com-sg/image/upload/v1638198312/wavemaker/Sprout-thumbnail_zhwkkt.jpg"
    ],
    thumbnail: 
      "https://res.cloudinary.com/startsomething-com-sg/image/upload/v1639018604/wavemaker/philippines_thumbnail_sffpms.jpg",
    video:
      "https://res.cloudinary.com/startsomething-com-sg/video/upload/v1640068119/wavemaker/ph_production_tfiuej.mp4",
    heading: "Philippines",
    description:
      "The Philippines has been known for many things—its thriving BPO industry, its never-ending supply of overseas Filipino workers, and its addiction to social media. What it’s not known for is software. Yet two startups are proving that with a well thought-out approach, you can build world-class software in the Philippines. These startups even managed to grow despite the pandemic."
  },
  {
    _id: "61b0f4869060647f9162a37a",
    images: [
      "https://res.cloudinary.com/startsomething-com-sg/image/upload/v1638167878/wavemaker/Fasal-photo-2_hu4lee.png",
      "https://res.cloudinary.com/startsomething-com-sg/image/upload/v1638167878/wavemaker/eFish-photo-16_naqtws.jpg"
    ],
    thumbnail: 
      "https://res.cloudinary.com/startsomething-com-sg/image/upload/v1639018604/wavemaker/agriculture_and_hardware_thumbnail_f8ysa1.jpg",
    video:
      "https://res.cloudinary.com/startsomething-com-sg/video/upload/v1640068120/wavemaker/agri_production_kt6erj.mp4",
    heading: "Agriculture & Hardware",
    description:
      "Hardware is hard in Southeast Asia. It requires heavy capital expenditure and complex supply chains. Agriculture is also hard. Emerging market farms are very different from the vineyards you see in wealthier countries. It may sound counterintuitive, but combining the two together actually unlocks tremendous value."
  },
  {
    _id: "61b0f5099060647f9162a37b",
    images: [
      "https://res.cloudinary.com/startsomething-com-sg/image/upload/v1638168133/wavemaker/Bazaar-photo-1_yb4niz.jpg",
      "https://res.cloudinary.com/startsomething-com-sg/image/upload/v1638168136/wavemaker/Gudangada-photo-4-warung_sglzl9.jpg"
    ],
    thumbnail: 
      "https://res.cloudinary.com/startsomething-com-sg/image/upload/v1639018604/wavemaker/b2b_marketplaces_thumbnail_u5v5hd.jpg",
    video:
      "https://res.cloudinary.com/startsomething-com-sg/video/upload/v1640068120/wavemaker/b2b_production_ljcvtb.mp4",
    heading: "B2B Marketplaces",
    description:
      "Despite the explosion of e-commerce and widening footprint of convenience stores across Southeast Asia, over 50% of grocery supply still flows through traditional retail stores. The warungs in Indonesia, sari-sari stores in the Philippines, tap hoas in Vietnam remain to be the backbone of the region’s FMCG industry. While these mom-and-pop stores weren’t spared from Covid’s impact, they’ve become even more relevant to their communities. But the stores suffer from inefficiencies—many of which have been around long before the pandemic."
  },
  {
    _id: "61b0f5859060647f9162a37c",
    images: [
      "https://res.cloudinary.com/startsomething-com-sg/image/upload/v1638199908/wavemaker/Borneo-thumbnail_dd3qtk.jpg",
      "https://res.cloudinary.com/startsomething-com-sg/image/upload/v1638199908/wavemaker/SilentEight-thumbnail_brwces.jpg"
    ],
    thumbnail: 
      "https://res.cloudinary.com/startsomething-com-sg/image/upload/v1639018604/wavemaker/security_thumbnail_fye6te.jpg",
    video:
      "https://res.cloudinary.com/startsomething-com-sg/video/upload/v1640068110/wavemaker/security_production_bgsyqd.mp4",
    heading: "Security",
    description:
      "In an increasingly digital, data-driven world, data security only becomes more critical. The sheer volume of data created inevitably leads to a lot of noise, which then makes security more difficult to manage. This is one of the cases where AI/machine learning can help. It’s no panacea. But with the right approach and the right training, you can apply the technology to solve meaningful problems."
  },
  {
    _id:"61b0f5d99060647f9162a37d",
    images: [
      "https://res.cloudinary.com/startsomething-com-sg/image/upload/v1638200488/wavemaker/Wmi-thumbnail-1_zze3wv.jpg", 
      "https://res.cloudinary.com/startsomething-com-sg/image/upload/v1638200487/wavemaker/WMi-thumbnail-2_h2bvsv.jpg"
    ],
    thumbnail: 
      "https://res.cloudinary.com/startsomething-com-sg/image/upload/v1639018604/wavemaker/wavemaker_impact_thumbnail_y59sxa.jpg",
    video:
      "https://res.cloudinary.com/startsomething-com-sg/video/upload/v1640068043/wavemaker/impact_production_llqdfd.mp4",
    heading: "Sustainability",
    description:
      "Climate change is coming for us. The three largest cities in Southeast Asia will be underwater by 2050. But there’s good news—we have a shot at halving the global carbon budget by creating incentives and changing people’s behavior. And that’s something that the venture world knows how to do. Introducing Wavemaker Impact, a first-of-its-kind climate tech venture builder in Southeast Asia."
  }
];

export const sections = [
  "Video Player",
  "Staying Ahead of the Curve",
  "Wavemaker Spotlight",
  "The Next Wave"
];

export const instructions = [
  {
    imageUrl: "/signup.svg",
    text: "Create an account and sign in"
  },
  {
    imageUrl: "/browse.svg",
    text: "Browse through our sections and videos"
  },
  {
    imageUrl: "/watch.svg",
    text: "Watch the video you want by clicking on the play button"
  },
];

export const faq = [
  {
    question: "What is #Wavemakers?",
    answer:
      "#Wavemakers is Wavemaker Partners’ annual Startup Showcase where select portfolio companies present their game-changing tech solutions in front of investors, corporates, government and media representatives, and other partners in the Wavemaker ecosystem. This Showcase is typically held during our week-long Annual General Meeting festivities."
  },
  {
    question: "What is this microsite about?",
    answer:
      "We created this microsite to share key highlights from the event in case you missed it.\nFeel free to explore insightful videos featuring pitches from our portfolio companies pitches starting from the year 2020.\nDo create an account/sign in to watch the videos. For the best viewing experience, we recommend using a desktop or laptop."
  },
  {
    question: "How do I reach out to the founders and Wavemaker Impact?",
    answer:
      "Please email orbit@wavemaker.vc if you want to get in touch with any of the Wavemaker Partner founders. Please email impact@wavemaker.vc if you want to connect with the Wavemaker Impact founders. The Wavemaker team will help connect you."
  },
  {
    question: "How do I reach out to Wavemaker Partners?",
    answer:
      "Please email orbit@wavemaker.vc for any feedback or concerns. If you want to pitch us, please email pitchsea@wavemaker.vc."
  },
  {
    question: "Why do I have to register with my work email?",
    answer:
      "To access this private website, we kindly ask that you register using your work email and provide an identifiable name, as this platform is exclusively for invited individuals. Please note that we reserve the right to remove any users from this website at our discretion. We apologise for any inconvenience this might cause."
  }
];
