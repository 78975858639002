import React, { useState, useEffect } from "react";

import api from "Api/wavemaker.api";

import Banner from "Components/Banner";
import Footer from "Components/Footer";
import HomeLayout from "Components/HomeLayout";
import Row from "Components/2022/Row";
import EntryDrawer from "Components/drawers/EntryDrawer";

import useFetch from "Hooks/useFetch";
import Head from "Components/Head";
import Blurb from "Components/2022/Blurb";

const Home = () => {
  const { data, loading, error } = useFetch(api.category);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    let wm2022Data = [];

    data.map(({ entry }) => {
      entry.map(item => {
        if (item.category[0].includes("WM2022")) {
          wm2022Data.push(item);
        } 
      });
    });

    wm2022Data.sort((a,b) => {
      const aName = a.companyName.toLowerCase();
      const bName = b.companyName.toLowerCase();
      if(aName < bName) { return -1; }
      if(aName > bName) { return 1; }
      return 0;
    })

    setCategories([
      { type: "WM2022", entry: wm2022Data }
    ]);
  }, [data]);

  return (
    <HomeLayout>
      <Head title="2022" />
      <Blurb></Blurb>
      <Banner firstFeatured={categories[0]?.entry[0]} />

      {categories.map(({ type, entry }) => (
        <Row key={type} type={type} entries={entry} />
      ))}

      <Footer year="2024" />

      <EntryDrawer />
    </HomeLayout>
  );
};

export default Home;
