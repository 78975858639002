import { configureStore } from "@reduxjs/toolkit";

import contentReducer from "./slices/contentSlice";
import openReducer from "./slices/openSlice";
import playlistReducer from "./slices/playlistSlice";
import userReducer from "./slices/userSlice";

export const store = configureStore({
  reducer: {
    content: contentReducer,
    open: openReducer,
    playlist: playlistReducer,
    user: userReducer
  }
});
