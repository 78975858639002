import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import Line from "./Line";

import { toggleLoginDialog } from "Slices/openSlice";
import { contentSelector, incrementViewCounter } from "Slices/contentSlice";
import { userSelector } from "Slices/userSlice";

const useStyles = makeStyles(theme => ({
  video: {
    width: "100%",
    maxHeight: "65vh",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    background: theme.palette.common.black
  },
  paragraph: {
    marginBottom: theme.spacing(10),
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(5)
    }
  }
}));

const VideoPlayer = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const videoEl = useRef();
  const { token } = useSelector(userSelector);
  const { currentVideo } = useSelector(contentSelector);
  const [prevVideo, setPrevVideo] = useState(
    "https://res.cloudinary.com/startsomething-com-sg/video/upload/v1640068120/wavemaker/agri_production_kt6erj.mp4"
  );

  const { video, heading, description, _id, thumbnail } = currentVideo;

  useEffect(() => {
    if (prevVideo !== video && token) {
      videoEl.current.play();
      dispatch(incrementViewCounter(_id));
      setPrevVideo(video);
    }
  }, [video]);

  useEffect(() => {
    videoEl.current.controls = token ? true : false;
  }, [token]);

  const handleClick = () => {
    dispatch(toggleLoginDialog());
  };

  return (
    <section id="video-player" className={classes.section}>
      <div className="video-container">
        <video
          ref={videoEl}
          src={video}
          controlsList="nodownload"
          className={classes.video}
          poster={thumbnail}
        ></video>

        {!token && (
          <img
            src="/play.svg"
            alt="Play"
            className="play-button play-button-large"
            onClick={handleClick}
          />
        )}
      </div>

      <Typography variant="body1" color="primary" paragraph>
        <strong>{heading}</strong>
      </Typography>

      <Typography variant="body1" color="primary" className={classes.paragraph}>
        {description}
      </Typography>

      <Line />
    </section>
  );
};

export default VideoPlayer;
