import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import api from "Api/wavemaker.api";
import axios from "Api/wavemaker.axios";

export const fetchPlaylist = createAsyncThunk(
  "playlist/fetchPlaylist",
  async (token, { fulfillWithValue, rejectWithValue }) => {
    try {
      const res = await axios.get(api.playlist, {
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": token
        }
      });

      const playlist = await res.data.playlist;
      return fulfillWithValue(playlist);
    } catch (err) {
      const errors = err.data.response.errors;
      return rejectWithValue(errors);
    }
  }
);

export const addToPlaylist = createAsyncThunk(
  "playlist/addToPlaylist",
  async (entry, { fulfillWithValue, rejectWithValue }) => {
    try {
      const res = await axios.patch(
        `${api.playlist}/add/${entry?._id}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": sessionStorage.getItem("token")
          }
        }
      );

      const message = await res.data.message;
      return fulfillWithValue({ entry, message });
    } catch (err) {
      const errors = err.data.response.errors;
      return rejectWithValue(errors);
    }
  }
);

export const removeFromPlaylist = createAsyncThunk(
  "playlist/removeFromPlaylist",
  async (entryId, { fulfillWithValue, rejectWithValue }) => {
    try {
      const res = await axios.patch(
        `${api.playlist}/remove/${entryId}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": sessionStorage.getItem("token")
          }
        }
      );

      const message = await res.data.message;
      return fulfillWithValue({ entryId, message });
    } catch (err) {
      const errors = err.data.response.errors;
      return rejectWithValue(errors);
    }
  }
);

export const incrementViewCounter = createAsyncThunk(
  "playlist/incrementViewCounter",
  async (entryId, { fulfillWithValue, rejectWithValue }) => {
    try {
      const res = await axios.patch(
        `${api.video}/${entryId}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": sessionStorage.getItem("token")
          }
        }
      );

      const views = await res.data.views;
      return fulfillWithValue(views);
    } catch (err) {
      const errors = err.data.response.errors;
      return rejectWithValue(errors);
    }
  }
);

export const playlistSlice = createSlice({
  name: "playlist",
  initialState: {
    playlist: [],
    queue: [],
    currentlyPlayingId: "",
    documents: [],
    isLoading: false,
    errors: null
  },
  reducers: {
    createQueue: (state, { payload }) => {
      document.getElementById("banner").scrollIntoView(true);
      //window.scrollTo(0, 0);
      return { ...state, queue: payload };
    },
    setCurrentlyPlayingId: (state, { payload }) => {
      return { ...state, currentlyPlayingId: payload };
    },
    setDocuments: (state, { payload }) => {
      return { ...state, documents: payload };
    }
  },
  extraReducers: {
    [fetchPlaylist.pending]: state => {
      state.isLoading = true;
    },
    [fetchPlaylist.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
      state.playlist = payload;
    },
    [fetchPlaylist.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload;
    },
    [addToPlaylist.pending]: state => {
      state.isLoading = true;
    },
    [addToPlaylist.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
      state.playlist.push(payload.entry);
    },
    [addToPlaylist.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload;
    },
    [removeFromPlaylist.pending]: state => {
      state.isLoading = true;
    },
    [removeFromPlaylist.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = null;
      state.playlist = state.playlist.filter(
        entry => entry._id !== payload.entryId
      );
    },
    [removeFromPlaylist.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload;
    },
    [incrementViewCounter.pending]: state => {
      state.isLoading = true;
    },
    [incrementViewCounter.fulfilled]: state => {
      state.isLoading = false;
      state.errors = null;
    },
    [incrementViewCounter.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload;
    }
  }
});

export const { createQueue, setCurrentlyPlayingId, setDocuments } =
  playlistSlice.actions;

export const playlistSelector = state => state.playlist;

export default playlistSlice.reducer;
