import React from "react";
import { Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Head from "Components/Head";
import Layout from "Components/Layout";

const useStyles = makeStyles(theme => ({
  container: {
    display: "grid",
    placeItems: "center",
    height: "calc(100vh - 64px)",
    textAlign: "center",
    color: theme.palette.primary.main
  },
  title: {
    fontSize: "clamp(5rem, 10vw, 12rem)",
    margin: 0
  },
  subtitle: {
    fontSize: "clamp(1.5rem, 2.5vw, 4rem)",
    marginTop: 0
  },
  link: {
    textDecoration: "none"
  }
}));

const Page404 = () => {
  const classes = useStyles();

  return (
    <Layout>
      <Container className={classes.container}>
        <Head title="Page Not Found" />

        <div>
          <h1 className={classes.title}>404</h1>
          <h3 className={classes.subtitle}>Page Not Found</h3>
          <Link to="/" className={classes.link}>
            <Button
              className={classes.button}
              color="secondary"
              variant="contained"
            >
              Back to Home
            </Button>
          </Link>
        </div>
      </Container>
    </Layout>
  );
};

export default Page404;
