import React, { useContext } from "react";
import { useSelector, useDispatch } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";

import AddToQueueIcon from "@material-ui/icons/AddToQueue";
import CloseIcon from "@material-ui/icons/Close";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import LanguageIcon from "@material-ui/icons/Language";
import EmailIcon from "@material-ui/icons/Email";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import RemoveFromQueueIcon from "@material-ui/icons/RemoveFromQueue";

import {
  openSelector,
  toggleDownloadDocumentsDialog,
  toggleEntryDrawer,
  toggleSnackbar
} from "Slices/openSlice";
import { contentSelector, setSnackbarMessage } from "Slices/contentSlice";
import { userSelector } from "Slices/userSlice";
import {
  addToPlaylist,
  createQueue,
  playlistSelector,
  removeFromPlaylist,
  setDocuments
} from "Slices/playlistSlice";

const useStyles = makeStyles(theme => ({
  paper: {
    height: 600,
    [theme.breakpoints.down("sm")]: {
      height: "auto",
      backgroundColor: theme.palette.common.black
    }
  },
  root: {
    position: "fixed",
    height: 600,
    left: 0,
    zIndex: 1,
    transition: "0.3s",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      height: 300,
      background: "#000"
    }
  },
  background: {
    display: "flex",
    height: "100%"
  },
  left: {
    position: "relative",
    background: "#000",
    width: "35%",
    "&:before": {
      content: '""',
      position: "absolute",
      backgroundImage: "linear-gradient(to right, #000, transparent)",
      top: 0,
      bottom: 0,
      left: "100%",
      width: 275
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  right: {
    width: "65%",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    [theme.breakpoints.down("sm")]: {
      display: "none",
      width: 0
    }
  },
  content: {
    color: theme.palette.common.white,
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    left: 0,
    padding: theme.spacing(0, 4),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(3, 2),
      position: "static",
      top: 0,
      transform: "none"
    }
  },
  title: {
    fontSize: 40,
    color: theme.palette.common.white,
    maxWidth: 500,
    margin: theme.spacing(0, 0, 2),
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      fontSize: 32
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "1rem"
    }
  },
  overview: {
    fontSize: 20,
    color: theme.palette.common.white,
    maxWidth: 500,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      fontSize: 16
    }
  },
  actionBtn: {
    margin: theme.spacing(0, 1, 1, 0),
    "&:nth-child(-n + 2)": {
      color: theme.palette.primary.main
    },
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(0, 0, 1, 0),
      width: "100%"
    }
  },
  closeBtn: {
    position: "absolute",
    top: theme.spacing(2),
    right: theme.spacing(2),
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.common.white
    },
    [theme.breakpoints.down("sm")]: {
      width: 40,
      height: 40,
      top: theme.spacing(1),
      right: theme.spacing(1)
    }
  },
  mailLink: {
    color: theme.palette.common.white,
    position: "absolute",
    bottom: theme.spacing(4),
    left: theme.spacing(4),
    textDecoration: "underline",
    [theme.breakpoints.down("sm")]: {
      position: "static"
    }
  },
  chip: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white
  },
  img: {
    width: 200,
    height: "100%",
    objectFit: "cover",
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginRight: 0
    }
  },
  flexbox: {
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column"
    }
  }
}));

const EntryDrawer = () => {
  const classes = useStyles();
  const { currentEntry: entry } = useSelector(contentSelector);
  const { entryDrawer } = useSelector(openSelector);
  const { token } = useSelector(userSelector);
  const dispatch = useDispatch();
  const { playlist } = useSelector(playlistSelector);

  const existsInPlaylist = playlist.some(x => x._id === entry?._id);

  const handleClick = entry => {
    if (existsInPlaylist) {
      dispatch(removeFromPlaylist(entry._id))
        .then(({ error, payload }) => {
          if (!error) {
            dispatch(setSnackbarMessage(payload?.message));
            dispatch(toggleSnackbar());
          } else {
            console.log(error);
          }
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      dispatch(addToPlaylist(entry))
        .then(({ error, payload }) => {
          if (!error) {
            dispatch(setSnackbarMessage(payload?.message));
            dispatch(toggleSnackbar());
          } else {
            console.log(error);
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  };

  const handleClose = () => {
    dispatch(toggleEntryDrawer());
  };

  const handlePlay = () => {
    dispatch(createQueue([entry]));
    handleClose();
  };

  const handleDownload = () => {
    const docs = entry?.downloads.map(item => ({
      _id: entry?._id,
      companyName: entry?.companyName,
      url: item.url,
      public_id: item.public_id
    }));

    dispatch(setDocuments(docs));
    dispatch(toggleDownloadDocumentsDialog());
  };

  const contactLink = entry?.email.includes("@")
    ? "mailto:" + entry?.email
    : entry?.email;

  return (
    <Drawer
      anchor="bottom"
      open={entryDrawer}
      onClose={handleClose}
      classes={{ paper: classes.paper }}
    >
      <Hidden smDown>
        <div className={classes.background}>
          <div className={classes.left}></div>
          <div
            className={classes.right}
            style={{
              backgroundImage: `url(${entry?.thumbnail})`
            }}
          ></div>
        </div>
      </Hidden>

      <div className={classes.content}>
        <div className={classes.flexbox}>
          <Hidden mdUp>
            <img
              src={entry?.thumbnail}
              alt={entry?.companyName}
              className={classes.img}
            />
          </Hidden>

          <div>
            <h2 className={classes.title}>{entry?.companyName}</h2>

            {entry?.tags.map(tag => (
              <Chip className={classes.chip} key={tag} label={tag} />
            ))}

            <p className={classes.overview}>{entry?.description}</p>
          </div>
        </div>

        {token && (
          <div>
            <Button
              className={classes.actionBtn}
              startIcon={<PlayArrowIcon />}
              onClick={handlePlay}
              variant="contained"
              color="default"
            >
              Play
            </Button>

            <Button
              className={classes.actionBtn}
              variant="contained"
              color="default"
              startIcon={
                existsInPlaylist ? <RemoveFromQueueIcon /> : <AddToQueueIcon />
              }
              onClick={() => handleClick(entry)}
            >
              {existsInPlaylist ? "Remove from Playlist" : "Add to Playlist"}
            </Button>

            {entry?.downloads.length > 0 && (
              <Button
                className={classes.actionBtn}
                startIcon={<FileCopyIcon />}
                onClick={handleDownload}
                variant="outlined"
                color="secondary"
              >
                Documents
              </Button>
            )}

            <Button
              className={classes.actionBtn}
              startIcon={<LanguageIcon />}
              onClick={() => window.open(entry?.url, "_blank")}
              variant="outlined"
              color="secondary"
            >
              Website
            </Button>

            <Button
              className={classes.actionBtn}
              startIcon={<EmailIcon />}
              onClick={() => window.open(contactLink, "_blank")}
              variant="outlined"
              color="secondary"
            >
              Email
            </Button>
          </div>
        )}
        <br />
        <br />

        {/* <Hidden mdUp>
          <br />
          <br />
          <Link className={classes.mailLink} href={contactLink} target="_blank">
            {entry?.email}
          </Link>
        </Hidden> */}
      </div>

      {/* {token && (
        <Hidden smDown>
          <Link className={classes.mailLink} href={contactLink} target="_blank">
            {entry?.email}
          </Link>
        </Hidden>
      )} */}

      <IconButton className={classes.closeBtn} onClick={handleClose}>
        <CloseIcon style={{ fontSize: 28 }} />
      </IconButton>
    </Drawer>
  );
};

export default EntryDrawer;
