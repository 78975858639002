import React, { useState, useEffect } from "react";

import api from "Api/wavemaker.api";

import Banner from "Components/Banner";
import Footer from "Components/Footer";
import HomeLayout from "Components/HomeLayout";
import Row from "Components/2023/Row";
import EntryDrawer from "Components/drawers/EntryDrawer";

import useFetch from "Hooks/useFetch";
import Head from "Components/Head";
import Blurb from "Components/2023/Blurb";

const Home = () => {
  const { data, loading, error } = useFetch(api.category);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    let regionalData = [];
    let impactData = [];
    let aiData = [];

    data.map(({ entry }) => {
      entry.map(item => {
        if (item.category[0].includes("Regional Plays & Enterprise Solutions")) {
          regionalData.push(item);
        } 
        if (item.category[0].includes("Impact & Sustainability")) {
          impactData.push(item);
        } 
        if (item.category[0].includes("AI & Cybersecurity")) {
          aiData.push(item);
        } 
        
      });
    });

    // regionalData.sort((a,b) => {
    //   const aName = a.companyName.toLowerCase();
    //   const bName = b.companyName.toLowerCase();
    //   if(aName < bName) { return -1; }
    //   if(aName > bName) { return 1; }
    //   return 0;
    // })

    // impactData.sort((a,b) => {
    //   const aName = a.companyName.toLowerCase();
    //   const bName = b.companyName.toLowerCase();
    //   if(aName < bName) { return -1; }
    //   if(aName > bName) { return 1; }
    //   return 0;
    // })

    // aiData.sort((a,b) => {
    //   const aName = a.companyName.toLowerCase();
    //   const bName = b.companyName.toLowerCase();
    //   if(aName < bName) { return -1; }
    //   if(aName > bName) { return 1; }
    //   return 0;
    // })

    setCategories([
      { type: "Regional Plays & Enterprise Solutions", entry: regionalData },
      { type: "Impact & Sustainability", entry: impactData },
      { type: "AI & Cybersecurity", entry: aiData }
    ]);
  }, [data]);

  return (
    <HomeLayout>
      <Head title="2023" />
      <Blurb></Blurb>
      <Banner firstFeatured={categories[0]?.entry[0]} />

      {categories.map(({ type, entry }) => (
        <Row key={type} type={type} entries={entry} />
      ))}

      <Footer year="2024" />

      <EntryDrawer />
    </HomeLayout>
  );
};

export default Home;
