import React, { useState, useEffect, useContext, forwardRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import api from "Api/wavemaker.api";
import axios from "Api/wavemaker.axios";

import { openSelector, toggleDownloadDocumentsDialog } from "Slices/openSlice";
import { playlistSelector } from "Slices/playlistSlice";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
  dialog: {
    zIndex: theme.zIndex.snackbar
  },
  container: {
    color: theme.palette.primary.main,
    minHeight: 150,
    maxHeight: 300,
    overflowY: "auto"
  },
  th: {
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
    padding: theme.spacing(0),
    color: theme.palette.primary.main,
    "&:not(:last-child)": {
      paddingRight: theme.spacing(2)
    },
    "&.MuiTableCell-sizeSmall:last-child": {
      paddingRight: 0
    }
  },
  td: {
    padding: theme.spacing(0),
    borderBottom: "none",
    color: theme.palette.primary.main,
    "&:not(:last-child)": {
      paddingRight: theme.spacing(2)
    },
    "&.MuiTableCell-sizeSmall:last-child": {
      paddingRight: 0
    }
  },
  checkbox: {
    padding: theme.spacing(0.5, 0)
  },
  error: {
    color: theme.palette.error.main,
    textAlign: "center",
    fontSize: 14
  },
  actions: {
    padding: theme.spacing(0, 3, 2)
  }
}));

const DownloadDocumentsDialog = () => {
  const classes = useStyles();
  const [selected, setSelected] = useState([]);
  const [error, setError] = useState(null);
  const { downloadDocumentsDialog } = useSelector(openSelector);
  const { documents } = useSelector(playlistSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    setSelected(documents.map(entry => entry));
  }, [documents]);

  const handleSelect = entry => {
    const selectedIndex = selected.indexOf(entry);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, entry);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleSelectAll = event => {
    if (event.target.checked) {
      const newSelected = documents.map(entry => entry);
      setSelected(newSelected);
    } else {
      setSelected([]);
    }
  };

  const handleDownload = () => {
    if (selected.length === 0) {
      setError("Please select at least one option");
      return;
    }

    let company_ids = [];
    let public_ids = [];

    selected.forEach(entry => {
      company_ids.push(entry._id);
      public_ids.push(entry.public_id);
    });

    const data = { company_ids, public_ids };

    axios
      .post(`${api.download}/getLink`, data, {
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": sessionStorage.getItem("token")
        }
      })
      .then(res => {
        window.open(res.data.link, "_blank");
        handleClose();
      })
      .catch(error => {
        console.error(error);
      });
  };

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }

    setSelected([]);
    setError(null);
    dispatch(toggleDownloadDocumentsDialog());
  };

  const cleanFileName = id => {
    return `${id.replace(/wavemaker\//, "")}.pdf`;
  };

  const isSelected = name => selected.indexOf(name) !== -1;

  return (
    <Dialog
      open={downloadDocumentsDialog}
      onClose={handleClose}
      TransitionComponent={Transition}
      fullWidth
      keepMounted
      className={classes.dialog}
    >
      <DialogContent>
        <TableContainer className={classes.container}>
          <Table className={classes.table} size="small">
            <TableHead>
              <TableRow>
                <TableCell className={classes.th}>Company</TableCell>
                <TableCell className={classes.th}>File Name</TableCell>
                <TableCell className={classes.th} align="right">
                  <Checkbox
                    color="primary"
                    indeterminate={
                      selected.length > 0 && selected.length < documents.length
                    }
                    checked={
                      documents.length > 0 &&
                      selected.length === documents.length
                    }
                    onChange={event => handleSelectAll(event)}
                    className={classes.checkbox}
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {documents.map(entry => (
                <TableRow key={entry.public_id}>
                  <TableCell className={classes.td}>
                    {entry.companyName.trim()}
                  </TableCell>
                  <TableCell className={classes.td}>
                    {cleanFileName(entry.public_id)}
                  </TableCell>
                  <TableCell className={classes.td} align="right">
                    <Checkbox
                      color="primary"
                      checked={isSelected(entry)}
                      onClick={() => handleSelect(entry)}
                      className={classes.checkbox}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>

      {error && <p className={classes.error}>{error}</p>}

      <DialogActions className={classes.actions}>
        <Button variant="contained" color="primary" onClick={handleDownload}>
          Download
        </Button>

        <Button variant="outlined" color="primary" onClick={handleClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DownloadDocumentsDialog;
