import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";

import { openSelector, toggleSnackbar } from "Slices/openSlice";
import { contentSelector } from "Slices/contentSlice";

const useStyles = makeStyles(theme => ({
  snackbar: {
    "& .MuiSnackbarContent-root": {
      backgroundColor: theme.palette.common.white,
      borderRadius: 0,
      color: theme.palette.primary.main,
      fontSize: 20,
      fontWeight: "bold",
      display: "block",
      position: "absolute",
      top: theme.spacing(5),
      right: theme.spacing(-3)
    },
    "& .MuiSnackbarContent-message": {
      textAlign: "center"
    }
  }
}));

const Toast = () => {
  const classes = useStyles();
  const { snackbar } = useSelector(openSelector);
  const { snackbarMessage } = useSelector(contentSelector);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(toggleSnackbar());
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      classes={{ root: classes.snackbar }}
      autoHideDuration={2000}
      open={snackbar}
      onClose={handleClose}
      message={snackbarMessage}
    />
  );
};

export default Toast;
