import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import { logoutUser, userSelector } from "Slices/userSlice";
import { toggleEditProfileDialog, toggleSnackbar } from "Slices/openSlice";
import { setSnackbarMessage } from "Slices/contentSlice";

const useStyles = makeStyles(theme => ({
  avatar: {
    backgroundColor: theme.palette.primary.main,
    cursor: "pointer"
  },
  list: {
    padding: 0,
    width: 120
  },
  paper: {
    borderRadius: 0
  }
}));

const AvatarButton = () => {
  const classes = useStyles();
  const { user } = useSelector(userSelector);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = type => {
    handleClose();

    if (type === "profile") {
      dispatch(toggleEditProfileDialog());
    } else {
      dispatch(logoutUser());
      dispatch(setSnackbarMessage("Logout successful!"));
      dispatch(toggleSnackbar());
    }
  };

  return (
    <>
      <Avatar className={classes.avatar} onClick={handleClick}>
        {user?.name?.charAt(0)}
      </Avatar>

      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        classes={{ list: classes.list, paper: classes.paper }}
      >
        <MenuItem onClick={() => handleSelect("profile")}>
          Edit Profile
        </MenuItem>
        <MenuItem onClick={() => handleSelect("logout")}>Logout</MenuItem>
      </Menu>
    </>
  );
};

export default AvatarButton;
