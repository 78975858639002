import React, { useState, useEffect } from "react";

import api from "Api/wavemaker.api";

import Banner from "Components/Banner";
import Footer from "Components/Footer";
import HomeLayout from "Components/HomeLayout";
import Row from "Components/Row";
import EntryDrawer from "Components/drawers/EntryDrawer";

import useFetch from "Hooks/useFetch";
import Head from "Components/Head";

const Page2020 = () => {
  const { data, loading, error } = useFetch(api.category);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    let wmData = [];
    let featuredData = [];

    data.map(({ entry }) => {
      entry.map(item => {
        if (item.category[0].includes("Wavemakers")) {
          wmData.push(item);
        } else if(item.category[0].includes("Featured")) {
          featuredData.push(item);
        }
      });
    });

    setCategories([
      { type: "Featured", entry: featuredData },
      { type: "Wavemakers", entry: wmData }
    ]);
  }, [data]);

  return (
    <HomeLayout>
      <Head title="2020" />

      <Banner firstFeatured={categories[0]?.entry[0]} />

      {categories.map(({ type, entry }) => (
        <Row key={type} type={type} entries={entry} />
      ))}

      <Footer year="2024" />

      <EntryDrawer />
    </HomeLayout>
  );
};

export default Page2020;
