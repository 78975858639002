import React from "react";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.primary.main,
    textAlign: "right",
    padding: theme.spacing(4, 8),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2)
    }
  }
}));

const Footer = ({ year = 2021 }) => {
  const classes = useStyles();

  return (
    <footer className={classes.root}>
      WAVEMAKER™ All Rights Reserved {year}
    </footer>
  );
};

export default Footer;
