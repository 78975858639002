import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Fade from "@material-ui/core/Fade";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Tooltip from "@material-ui/core/Tooltip";
import GetAppIcon from "@material-ui/icons/GetApp";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";

import {
  openSelector,
  toggleDownloadDocumentsDialog,
  togglePlaylistDrawer
} from "Slices/openSlice";
import {
  createQueue,
  playlistSelector,
  removeFromPlaylist,
  setDocuments
} from "Slices/playlistSlice";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    top: 0,
    width: 400,
    position: "fixed",
    height: "calc(100vh - 64px)",
    right: 0,
    boxShadow: theme.shadows[4],
    marginTop: theme.spacing(8),
    zIndex: theme.zIndex.drawer,
    overflowY: "hidden",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "calc(100vh - 56px)",
      marginTop: theme.spacing(7)
    }
  },
  list: {
    padding: 0,
    overflowX: "auto",
    height: "calc(100vh - 160px)"
  },
  playlistImg: {
    height: 75
  },
  text: {
    marginLeft: 20,
    color: theme.palette.primary.main
  },
  emptyPlaylist: {
    height: "calc(100vh - 60px)",
    display: "grid",
    placeItems: "center",
    color: theme.palette.grey[500],
    fontSize: 24,
    [theme.breakpoints.down("xs")]: {
      height: "calc(100vh - 56px)"
    },
    textAlign: "center",
    "& p": {
      margin: 0,
      textTransform: "uppercase"
    }
  },
  icon: {
    fontSize: 80
  },
  btnWrapper: {
    fontSize: 16,
    position: "absolute",
    bottom: 0,
    width: "100%"
  },
  playlistBtn: {
    height: 50,
    borderRadius: 0,
    boxShadow: "none"
  }
}));

const Playlist = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { playlistDrawer } = useSelector(openSelector);
  const { playlist, currentlyPlayingId } = useSelector(playlistSelector);

  const handleClose = () => {
    dispatch(togglePlaylistDrawer());
  };

  const handleItemClick = index => {
    dispatch(createQueue(playlist.slice(index)));
    handleClose();
  };

  const handlePlayAll = () => {
    dispatch(createQueue([...playlist]));
    handleClose();
  };

  const handleDownloadAll = () => {
    // Prepare documents
    const docs = [];

    playlist.forEach(entry => {
      entry.downloads.forEach(item => {
        docs.push({
          _id: entry._id,
          companyName: entry.companyName,
          url: item.url,
          public_id: item.public_id
        });
      });
    });

    dispatch(setDocuments(docs));
    dispatch(toggleDownloadDocumentsDialog());
    handleClose();
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Fade in={playlistDrawer} timeout={300}>
        {playlist.length > 0 ? (
          <div className={classes.root}>
            <List className={classes.list}>
              {playlist.map((entry, index) => (
                <ListItem
                  className={classes.listItem}
                  key={entry?._id}
                  button
                  onClick={() => handleItemClick(index)}
                  selected={currentlyPlayingId === entry?._id}
                >
                  <img
                    src={entry?.thumbnail}
                    alt={entry?.companyName}
                    className={classes.playlistImg}
                  />
                  <ListItemText
                    primary={entry?.companyName}
                    secondary={entry?.tags.join(", ")}
                    className={classes.text}
                  />
                  <ListItemSecondaryAction>
                    <Tooltip title="Remove from playlist">
                      <IconButton
                        edge="end"
                        color="primary"
                        onClick={() => dispatch(removeFromPlaylist(entry?._id))}
                      >
                        <RemoveCircleOutlineIcon />
                      </IconButton>
                    </Tooltip>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>

            <div className={classes.btnWrapper}>
              <Button
                color="primary"
                fullWidth
                variant="contained"
                className={classes.playlistBtn}
                onClick={handlePlayAll}
                startIcon={<PlayCircleFilledIcon />}
              >
                Play All
              </Button>
              <Button
                color="default"
                fullWidth
                variant="contained"
                className={classes.playlistBtn}
                onClick={handleDownloadAll}
                startIcon={<GetAppIcon />}
              >
                Download All
              </Button>
            </div>
          </div>
        ) : (
          <div className={`${classes.root} ${classes.emptyPlaylist}`}>
            <div>
              <SentimentVeryDissatisfiedIcon className={classes.icon} />
              <p>Nothing here yet</p>
            </div>
          </div>
        )}
      </Fade>
    </ClickAwayListener>
  );
};

export default Playlist;
