import React from "react";
import { Link, NavLink } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";

import Submenu from "./Submenu";

const useStyles = makeStyles(theme => ({
  center: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    gap: theme.spacing(4),
    justifyContent: "space-between",
    alignItems: "center",
    width: 730.97,
    paddingLeft: 107.49,
    [theme.breakpoints.down("sm")]: {
      width: 56,
      paddingLeft: 0
    }
  },
  logo: {
    height: 50
  },
  link: {
    textDecoration: "none",
    padding: "22.5px 0",
    position: "relative",
    color: "inherit",
    letterSpacing: 2,
    textAlign: "center",
    "&:after": {
      content: "''",
      position: "absolute",
      height: 1,
      width: "100%",
      bottom: "20%",
      left: 0,
      backgroundColor: theme.palette.common.black,
      opacity: 0,
      transitionDuration: theme.transitions.duration.standard
    },
    "&:hover": {
      "&:after": {
        opacity: 1
      }
    },
    "&:first-child:hover .submenu": {
      opacity: 1,
      visibility: "visible",
      transform: "translateY(0)"
    }
  },
  activeLink: {
    "&:after": {
      content: "''",
      position: "absolute",
      height: 1,
      width: "100%",
      bottom: "20%",
      left: 0,
      backgroundColor: theme.palette.common.black,
      opacity: 1,
      transitionDuration: theme.transitions.duration.standard
    }
  }
}));

const Navbar = () => {
  const classes = useStyles();

  const checkIfActive = (match, location) => {
    const pathname = location.pathname;
    return pathname === "/" || pathname === "/2020" || pathname === "/2021" || pathname === "/2022";
  };

  const handleClick = e => {
    e.preventDefault();
  };

  return (
    <nav className={classes.center}>
      <Hidden smDown>
        <NavLink
          to="/"
          exact
          isActive={checkIfActive}
          className={classes.link}
          onClick={handleClick}
          activeClassName={classes.activeLink}
        >
          Hashtag Home
          <Submenu />
        </NavLink>

        <NavLink
          to="/faq"
          exact
          className={classes.link}
          onClick={() => window.scrollTo(0, 0)}
          activeClassName={classes.activeLink}
        >
          FAQ
        </NavLink>
      </Hidden>

      <Link to="/">
        <img
          className={classes.logo}
          src="/logo.png"
          alt="Wavemaker Logo"
          title="Wavemaker"
        />
      </Link>

      <Hidden smDown>
        <a
          href="https://wavemaker.vc/sea/"
          target="_blank"
          className={classes.link}
          rel="noreferrer"
        >
          Wavemaker SEA
        </a>

        <a
          href="https://wavemaker.vc/sea/team"
          target="_blank"
          className={classes.link}
          rel="noreferrer"
        >
          Meet the Team
        </a>
      </Hidden>
    </nav>
  );
};

export default Navbar;
