import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ImageList from "@material-ui/core/ImageList";
import ImageListItem from "@material-ui/core/ImageListItem";

import { setCurrentVideo } from "Slices/contentSlice";
import { toggleLoginDialog } from "Slices/openSlice";
import { userSelector } from "Slices/userSlice";

const useStyles = makeStyles(theme => ({
  imageItem: {
    overflow: "visible"
  },
  image: {
    objectFit: "cover",
    width: "100%",
    height: "100%",
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[4]
  },
  description: {
    marginBottom: "1.5rem"
  }
}));

const TheNextWave = ({ item }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { token } = useSelector(userSelector);

  const { images, thumbnail, heading, description } = item;

  const handleClick = () => {
    dispatch(setCurrentVideo(item));
    window.scrollTo(0, 0);

    if (!token) {
      dispatch(toggleLoginDialog());
    }
  };

  return (
    <section id="the-next-wave" className="last-section">
      <Typography variant="h5" color="primary" className="title">
        THE NEXT WAVE
      </Typography>

      <Grid container spacing={2} alignItems="flex-end">
        <Grid item xs={12} lg={8}>
          <div className="video-container" onClick={handleClick}>
            <img src={thumbnail} alt={heading} className="video2021" />
            <img src="/play.svg" alt="Play" className="play-button" />
          </div>
        </Grid>

        <Grid item xs={12} lg={4}>
          <Typography variant="body1" color="primary" paragraph>
            <strong>{heading}</strong>
          </Typography>

          <Typography
            variant="body1"
            color="primary"
            className={classes.description}
          >
            {description}
          </Typography>

          <ImageList
            rowHeight={"auto"}
            className={classes.imageList}
            cols={2}
            gap={16}
          >
            {images.map(image => (
              <ImageListItem key={image} classes={{ item: classes.imageItem }}>
                <img
                  src={image}
                  alt={heading}
                  title={heading}
                  className={classes.image}
                />
              </ImageListItem>
            ))}
          </ImageList>
        </Grid>
      </Grid>
    </section>
  );
};

export default TheNextWave;
