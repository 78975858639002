import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import api from "Api/wavemaker.api";
import axios from "Api/wavemaker.axios";

import { content2021 } from "Api/data";

export const incrementViewCounter = createAsyncThunk(
  "content/incrementViewCounter",
  async (entryId, { fulfillWithValue, rejectWithValue }) => {
    try {
      const res = await axios.patch(
        `${api.content2021}/${entryId}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": sessionStorage.getItem("token")
          }
        }
      );

      const views = await res.data.view;
      return fulfillWithValue(views);
    } catch (err) {
      const errors = err.data.response.errors;
      return rejectWithValue(errors);
    }
  }
);

export const contentSlice = createSlice({
  name: "content",
  initialState: {
    currentVideo: content2021[2],
    currentEntry: null,
    snackbarMessage: ""
  },
  reducers: {
    setCurrentVideo: (state, { payload }) => {
      return { ...state, currentVideo: payload };
    },
    setCurrentEntry: (state, { payload }) => {
      return { ...state, currentEntry: payload };
    },
    setSnackbarMessage: (state, { payload }) => {
      return { ...state, snackbarMessage: payload };
    }
  },
  extraReducers: {
    [incrementViewCounter.pending]: state => {
      state.isLoading = true;
    },
    [incrementViewCounter.fulfilled]: state => {
      state.isLoading = false;
      state.errors = null;
    },
    [incrementViewCounter.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload;
    }
  }
});

export const { setCurrentVideo, setCurrentEntry, setSnackbarMessage } =
  contentSlice.actions;

export const contentSelector = state => state.content;

export default contentSlice.reducer;
