import React, { forwardRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import Slide from "@material-ui/core/Slide";
import TextField from "@material-ui/core/TextField";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import PersonOutlinedIcon from "@material-ui/icons/PersonOutlined";

import {
  editName,
  editPassword,
  clearErrors,
  userSelector
} from "Slices/userSlice";
import {
  openSelector,
  toggleEditProfileDialog,
  toggleSnackbar
} from "Slices/openSlice";
import { setSnackbarMessage } from "Slices/contentSlice";

const useStyles = makeStyles(theme => ({
  title: {
    color: theme.palette.primary.main,
    textAlign: "center",
    fontSize: 32,
    fontWeight: 500,
    marginTop: theme.spacing(2)
  },
  textField: {
    margin: theme.spacing(1, 0),
    "& input": {
      fontSize: 14
    }
  },
  button: {
    width: 100
  },
  icon: {
    color: theme.palette.grey[500]
  },
  actions: {
    padding: theme.spacing(0, 3, 3)
  }
}));

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EditProfileDialog = () => {
  const classes = useStyles();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors: feErrors }
  } = useForm();
  const { user, errors } = useSelector(userSelector);
  const { editProfileDialog } = useSelector(openSelector);
  const dispatch = useDispatch();
  const smUp = useMediaQuery(theme => theme.breakpoints.up("sm"));

  const onSubmit = data => {
    const { name, oldPassword, password } = data;

    const trimmedName = name.trim();

    if (trimmedName === user?.name && (oldPassword === "" || password === "")) {
      handleCancel();
      return;
    }

    checkName(trimmedName, oldPassword, password);
    checkPassword(trimmedName, oldPassword, password);
  };

  const checkName = (trimmedName, oldPassword, password) => {
    if (trimmedName !== user?.name) {
      dispatch(editName({ name: trimmedName }))
        .then(({ error, payload }) => {
          if (!error) {
            dispatch(setSnackbarMessage(payload?.message));
            dispatch(toggleSnackbar(true));

            if (oldPassword === "" || password === "") {
              handleClose();
            }
          } else {
            console.log(error);
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  };

  const checkPassword = (trimmedName, oldPassword, password) => {
    if (oldPassword !== "" && password !== "") {
      dispatch(editPassword({ oldPassword, password }))
        .then(({ error, payload }) => {
          if (!error) {
            reset({ name: trimmedName, oldPassword: "", password: "" });
            dispatch(setSnackbarMessage(payload?.message));
            dispatch(toggleSnackbar(true));
            handleClose();
          } else {
            console.log(error);
          }
        })
        .catch(err => {
          console.log(err);
        });
    } else if (oldPassword === "" || password === "") {
      reset({ name: trimmedName, oldPassword: "", password: "" });
    }
  };

  const handleCancel = () => {
    reset();
    handleClose();
  };

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }

    dispatch(clearErrors());
    dispatch(toggleEditProfileDialog(false));
  };

  return (
    <Dialog
      open={editProfileDialog}
      onClose={handleClose}
      TransitionComponent={Transition}
      fullWidth
      keepMounted
    >
      <DialogTitle className={classes.title} disableTypography>
        Edit Profile
      </DialogTitle>

      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <DialogContent>
          <Grid container spacing={smUp ? 3 : 0}>
            <Grid item xs={12} sm={6}>
              <TextField
                placeholder="Name"
                type="text"
                color="primary"
                fullWidth
                error={feErrors?.hasOwnProperty("name")}
                helperText={feErrors?.name?.message}
                defaultValue={user?.name}
                className={classes.textField}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonOutlinedIcon className={classes.icon} />
                    </InputAdornment>
                  )
                }}
                {...register("name", {
                  required: {
                    value: true,
                    message: "Name is required"
                  }
                })}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                type="email"
                fullWidth
                color="primary"
                disabled
                value={user?.email}
                className={classes.textField}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailOutlinedIcon className={classes.icon} />
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
          </Grid>

          <TextField
            placeholder="Enter old password"
            type="password"
            color="primary"
            fullWidth
            className={classes.textField}
            error={errors?.hasOwnProperty("oldPassword")}
            helperText={errors?.oldPassword}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockOutlinedIcon className={classes.icon} />
                </InputAdornment>
              )
            }}
            {...register("oldPassword")}
          />

          <TextField
            placeholder={`Enter new password ${
              smUp ? "(unchanged if empty)" : ""
            }`}
            type="password"
            color="primary"
            fullWidth
            error={errors?.hasOwnProperty("password")}
            helperText={errors?.password}
            className={classes.textField}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockOutlinedIcon className={classes.icon} />
                </InputAdornment>
              )
            }}
            {...register("password")}
          />
        </DialogContent>

        <DialogActions className={classes.actions}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.button}
          >
            Save
          </Button>

          <Button
            variant="outlined"
            color="primary"
            className={classes.button}
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default EditProfileDialog;
