import React from "react";
import { useDispatch } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";

import { toggleNavDrawer } from "Slices/openSlice";

const useStyles = makeStyles(theme => ({
  button: {
    marginLeft: theme.spacing(-2)
  }
}));

const MenuButton = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <Hidden mdUp>
      <IconButton
        className={classes.button}
        color="primary"
        onClick={() => dispatch(toggleNavDrawer())}
      >
        <MenuIcon style={{ fontSize: 32 }} />
      </IconButton>
    </Hidden>
  );
};

export default MenuButton;
