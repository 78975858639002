import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import api from "Api/wavemaker.api";
import axios from "Api/wavemaker.axios";

const checkParam = field => err => err.param === field;

export const loginUser = createAsyncThunk(
  "user/loginUser",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axios.post(api.login, data, {
        headers: {
          "Content-Type": "application/json"
        }
      });

      const token = await res.data.token;
      sessionStorage.setItem("token", token);
    } catch (err) {
      const allErrors = err.response.data.errors;
      const firstErrorMessage = allErrors[0].msg;
      const errors = {};

      if (allErrors.some(checkParam("email"))) {
        errors.email = allErrors.find(checkParam("email")).msg;
      }

      if (allErrors.some(checkParam("password"))) {
        errors.password = allErrors.find(checkParam("password")).msg;
      }

      if (firstErrorMessage === "Invalid Credentials") {
        errors.credentials = firstErrorMessage;
      }

      return rejectWithValue(errors);
    }
  }
);

export const registerUser = createAsyncThunk(
  "user/registerUser",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axios.post(api.register, data, {
        headers: {
          "Content-Type": "application/json"
        }
      });

      const token = await res.data.token;
      sessionStorage.setItem("token", token);
    } catch (err) {
      const allErrors = err.response.data.errors;
      const firstErrorMessage = allErrors[0].msg;
      const errors = {};

      if (allErrors.some(checkParam("name"))) {
        errors.name = allErrors.find(checkParam("name")).msg;
      }

      if (allErrors.some(checkParam("email"))) {
        errors.email = allErrors.find(checkParam("email")).msg;
      }

      if (allErrors.some(checkParam("password"))) {
        errors.password = allErrors.find(checkParam("password")).msg;
      }

      if (firstErrorMessage === "Email already exists") {
        errors.email = firstErrorMessage;
      }

      return rejectWithValue(errors);
    }
  }
);

export const fetchUser = createAsyncThunk(
  "user/fetchUser",
  async (token, { fulfillWithValue, rejectWithValue }) => {
    try {
      const res = await axios.get(api.register, {
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": token
        }
      });

      const user = await res.data.user;
      return fulfillWithValue(user);
    } catch (err) {
      const errors = err.data.response.errors;
      return rejectWithValue(errors);
    }
  }
);

export const editName = createAsyncThunk(
  "user/editUser",
  async (data, { fulfillWithValue, rejectWithValue }) => {
    try {
      const res = await axios.patch(api.register, data, {
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": sessionStorage.getItem("token")
        }
      });

      const message = await res.data.message;
      return fulfillWithValue({ name: data.name, message });
    } catch (err) {
      const errors = err.response.data.errors;
      return rejectWithValue(errors);
    }
  }
);

export const editPassword = createAsyncThunk(
  "user/editPassword",
  async (data, { fulfillWithValue, rejectWithValue }) => {
    try {
      const res = await axios.patch(api.editPassword, data, {
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": sessionStorage.getItem("token")
        }
      });

      const message = await res.data.message;
      return fulfillWithValue({ message });
    } catch (err) {
      const allErrors = err.response.data.errors;
      const errors = {};
      const firstErrMsg = allErrors[0].msg;

      if (firstErrMsg.includes("Wrong password")) {
        errors.oldPassword = firstErrMsg;
      } else {
        errors.password = firstErrMsg;
      }

      return rejectWithValue(errors);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "user/resetPassword",
  async (data, { fulfillWithValue, rejectWithValue }) => {
    try {
      const res = await axios.post(api.resetPassword, data, {
        headers: {
          "Content-Type": "application/json"
        }
      });

      const message = res.data.message;
      return fulfillWithValue({ message });
    } catch (err) {
      const allErrors = err.response.data.errors;
      const errors = {};
      const firstErrorMessage = allErrors[0].msg;

      if (firstErrorMessage) {
        errors.email = firstErrorMessage;
      }

      return rejectWithValue(errors);
    }
  }
);

const initialState = {
  user: null,
  isLoading: false,
  errors: null,
  token: sessionStorage.getItem("token"),
  message: ""
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    clearErrors: state => {
      return { ...state, errors: null };
    },
    logoutUser: () => {
      sessionStorage.removeItem("token");
      return initialState;
    }
  },
  extraReducers: {
    [loginUser.pending]: state => {
      state.isLoading = true;
    },
    [loginUser.fulfilled]: state => {
      state.isLoading = false;
      state.errors = null;
      state.token = sessionStorage.getItem("token");
    },
    [loginUser.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload;
    },
    [registerUser.pending]: state => {
      state.isLoading = true;
    },
    [registerUser.fulfilled]: state => {
      state.isLoading = false;
      state.errors = null;
      state.token = sessionStorage.getItem("token");
    },
    [registerUser.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload;
    },
    [resetPassword.pending]: state => {
      state.isLoading = true;
    },
    [resetPassword.fulfilled]: state => {
      state.isLoading = false;
      state.errors = null;
    },
    [resetPassword.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload;
    },
    [fetchUser.pending]: state => {
      state.isLoading = true;
    },
    [fetchUser.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.user = payload;
    },
    [fetchUser.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload;
    },
    [editName.pending]: state => {
      state.isLoading = true;
    },
    [editName.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.user.name = payload.name;
    },
    [editName.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload;
    },
    [editPassword.pending]: state => {
      state.isLoading = true;
    },
    [editPassword.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
    },
    [editPassword.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.errors = payload;
    }
  }
});

export const { clearErrors, logoutUser } = userSlice.actions;

export const userSelector = state => state.user;

export default userSlice.reducer;
