import { createSlice } from "@reduxjs/toolkit";

export const openSlice = createSlice({
  name: "open",
  initialState: {
    navDrawer: false,
    entryDrawer: false,
    playlistDrawer: false,
    loginDialog: false,
    registerDialog: false,
    resetPasswordDialog: false,
    editProfileDialog: false,
    downloadDocumentsDialog: false,
    snackbar: false
  },
  reducers: {
    toggleNavDrawer: state => {
      return { ...state, navDrawer: !state.navDrawer };
    },
    toggleEntryDrawer: state => {
      return { ...state, entryDrawer: !state.entryDrawer };
    },
    togglePlaylistDrawer: state => {
      return { ...state, playlistDrawer: !state.playlistDrawer };
    },
    toggleLoginDialog: state => {
      return { ...state, loginDialog: !state.loginDialog };
    },
    toggleRegisterDialog: state => {
      return { ...state, registerDialog: !state.registerDialog };
    },
    toggleResetPasswordDialog: state => {
      return { ...state, resetPasswordDialog: !state.resetPasswordDialog };
    },
    toggleEditProfileDialog: (state, { payload }) => {
      return {
        ...state,
        editProfileDialog: !state.editProfileDialog || payload
      };
    },
    toggleDownloadDocumentsDialog: state => {
      return {
        ...state,
        downloadDocumentsDialog: !state.downloadDocumentsDialog
      };
    },
    toggleSnackbar: (state, { payload }) => {
      return { ...state, snackbar: !state.snackbar || payload };
    }
  }
});

export const {
  toggleNavDrawer,
  toggleEntryDrawer,
  togglePlaylistDrawer,
  toggleLoginDialog,
  toggleRegisterDialog,
  toggleResetPasswordDialog,
  toggleEditProfileDialog,
  toggleDownloadDocumentsDialog,
  toggleSnackbar
} = openSlice.actions;

export const openSelector = state => state.open;

export default openSlice.reducer;
