import React, { useEffect } from "react";
import { hot } from "react-hot-loader/root";
import { useSelector, useDispatch } from "react-redux";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";

import FAQ from "Pages/FAQ";
import Home from "Pages/Home";
import Page2020 from "Pages/2020";
import Page2021 from "Pages/2021";
import Page2022 from "Pages/2022";
import Page404 from "Pages/404";

import { fetchPlaylist } from "Slices/playlistSlice";
import { fetchUser, userSelector } from "Slices/userSlice";

import "./index.css";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0745af",
      alt: "#8491a8"
    },
    secondary: {
      main: "#3d83f6"
    }
  }
});

const App = () => {
  const { token } = useSelector(userSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (token) {
      dispatch(fetchUser(token));
      dispatch(fetchPlaylist(token));
    }
  }, [token]);

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/faq" component={FAQ} />
          <Route path="/2020" component={Page2020} />
          <Route path="/2021" component={Page2021} />
          <Route path="/2022" component={Page2022} />
          <Route component={Page404} />
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default hot(App);
