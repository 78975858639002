import axios from "axios";

const wavemaker = axios.create({
  //baseURL: "http://localhost:5000/api/v1",
  //baseURL: "http://54.169.136.170/api/v1",
  baseURL: "https://hashtag-be.wavemaker.vc/api/v1",
  proxy: false
});

export default wavemaker;
