import React from "react";
import { useDispatch } from "react-redux";

import Button from "@material-ui/core/Button";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";

import { toggleLoginDialog } from "Slices/openSlice";

const LoginButton = () => {
  const dispatch = useDispatch();

  return (
    <Button
      onClick={() => dispatch(toggleLoginDialog())}
      startIcon={<PersonOutlineIcon />}
      variant="outlined"
      color="primary"
    >
      Sign In to View
    </Button>
  );
};

export default LoginButton;
