import React from "react";

import Divider from "@material-ui/core/Divider";

const Line = () => {
  return (
    <div className="divider-container">
      <Divider className="divider" light />
    </div>
  );
};

export default Line;
