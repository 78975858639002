import React from "react";
import { useDispatch } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

import { setCurrentEntry } from "Slices/contentSlice";
import { toggleEntryDrawer } from "Slices/openSlice";

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(0, 8, 5),
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(0, 2, 5)
    }
  },
  type: {
    marginBottom: 10,
    color: theme.palette.primary.main
  },
  slide: {
    cursor: "pointer"
  },
  slideImage: {
    width: "100%",
    objectFit: "contain"
  },
  companyName: {
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(3),
    display: "block",
    fontSize: 20,
    [theme.breakpoints.down("xs")]: {
      fontSize: 18,
      marginBottom: theme.spacing(2)
    }
  }
}));

const Row = ({ type, entries }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleClick = entry => {
    dispatch(toggleEntryDrawer());
    dispatch(setCurrentEntry(entry));
  };

  return (
    <div className={classes.root}>
      <h2 className={classes.type}>{type}</h2>

      <Grid container spacing={1}>
        {entries.map(entry => (
          <Grid
            key={entry._id}
            item
            xs={12}
            sm={6}
            lg={4}
            onClick={() => handleClick(entry)}
            className={classes.slide}
          >
            <img
              className={classes.slideImage}
              src={entry.thumbnail}
              alt={entry.companyName}
              title={entry.companyName}
            />

            <strong className={classes.companyName}>{entry.companyName}</strong>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default Row;
