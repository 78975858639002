import React, { forwardRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import InputAdornment from "@material-ui/core/InputAdornment";
import Slide from "@material-ui/core/Slide";
import TextField from "@material-ui/core/TextField";
import ChevronLeftRoundedIcon from "@material-ui/icons/ChevronLeftRounded";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";

import {
  openSelector,
  toggleLoginDialog,
  toggleResetPasswordDialog,
  toggleSnackbar
} from "Slices/openSlice";
import { resetPassword, userSelector, clearErrors } from "Slices/userSlice";
import { setSnackbarMessage } from "Slices/contentSlice";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(5, 10),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(4, 2)
    }
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: 36,
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      marginTop: 0
    }
  },
  textField: {
    margin: theme.spacing(1, 0),
    "& input": {
      fontSize: 14
    }
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  button: {
    marginTop: theme.spacing(3),
    fontSize: 18,
    fontWeight: "bold",
    color: theme.palette.primary.main,
    textTransform: "none"
  },
  backButton: {
    position: "absolute",
    top: 0,
    left: 0,
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  backIcon: {
    fontSize: "40px !important"
  }
}));

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ResetPasswordDialog = () => {
  const classes = useStyles();
  const { register, handleSubmit, reset } = useForm();
  const { resetPasswordDialog } = useSelector(openSelector);
  const { errors } = useSelector(userSelector);
  const dispatch = useDispatch();

  const onSubmit = data => {
    dispatch(resetPassword(data))
      .then(({ error, payload }) => {
        if (!error) {
          handleClose();
          dispatch(setSnackbarMessage(payload?.message));
          dispatch(toggleSnackbar());
        } else {
          console.log(error);
        }
      })
      .catch(err => console.log(err));
  };

  const handleClick = () => {
    handleClose();

    const timeout = setTimeout(() => {
      dispatch(toggleLoginDialog());
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  };

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }

    dispatch(toggleResetPasswordDialog());
    dispatch(clearErrors());
    reset();
  };

  return (
    <Dialog
      open={resetPasswordDialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth
      onClose={handleClose}
    >
      <div className={classes.root}>
        <Button
          className={classes.backButton}
          startIcon={<ChevronLeftRoundedIcon className={classes.backIcon} />}
          color="primary"
          disableRipple
          onClick={handleClick}
        >
          Back
        </Button>

        <h1 className={classes.title}>Reset Password</h1>

        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <TextField
            type="text"
            placeholder="Enter email"
            fullWidth
            color="primary"
            error={errors?.hasOwnProperty("email")}
            helperText={errors?.email}
            className={classes.textField}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailOutlinedIcon className={classes.icon} />
                </InputAdornment>
              )
            }}
            {...register("email")}
          />

          <Button
            variant="outlined"
            type="submit"
            fullWidth
            className={classes.button}
          >
            Reset Password
          </Button>
        </form>
      </div>
    </Dialog>
  );
};

export default ResetPasswordDialog;
