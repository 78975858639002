import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import { faq, instructions } from "Api/data";

import Head from "Components/Head";
import Layout from "Components/Layout";
import Footer from "Components/Footer";

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(7),
    color: theme.palette.primary.main
  },
  title: {
    textAlign: "center",
    fontSize: 80,
    padding: "30px 20px",
    [theme.breakpoints.down("sm")]: {
      fontSize: 60
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 48
    }
  },
  paper: {
    height: 300,
    width: 300,
    backgroundColor: "#ddd",
    padding: theme.spacing(2),
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    color: theme.palette.primary.main,
    margin: theme.spacing(0, "auto")
  },
  img: {
    width: 125,
    display: "block"
  },
  faq: {
    fontSize: 20
  },
  question: {
    fontSize: 24
  },
  answer: {
    marginBottom: 56,
    display: "block"
  }
}));

const FAQ = () => {
  const classes = useStyles();

  return (
    <Layout>
      <Container maxWidth="lg" className={classes.root}>
        <Head title="FAQ" />

        <h1 className={classes.title}>It's really simple!</h1>

        <Grid container spacing={5}>
          {instructions.map(instruction => (
            <Grid key={instruction.text} item xs={12} sm={12} md={4}>
              <Paper className={classes.paper}>
                <img
                  src={instruction.imageUrl}
                  alt={instruction.text}
                  className={classes.img}
                />
                <span>
                  <strong>{instruction.text.split(" ")[0]}</strong>{" "}
                  {instruction.text.split(" ").slice(1).join(" ")}
                </span>
              </Paper>
            </Grid>
          ))}
        </Grid>

        <h1 className={classes.title}>Want to know more?</h1>

        <div className={classes.faq}>
          {faq.map(val => (
            <div key={val.question}>
              <span className={classes.question}>
                <strong>
                  <p>{val.question}</p>
                </strong>
              </span>

              <span className={classes.answer}>
                {val.answer.split("\n").map(str => (
                  <p key={str}>{str}</p>
                ))}
              </span>
            </div>
          ))}
        </div>
      </Container>

      <Footer />
    </Layout>
  );
};

export default FAQ;
