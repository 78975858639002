import React from "react";
import { Link } from "react-scroll";

import { makeStyles } from "@material-ui/core/styles";

import { sections } from "Api/data";

const useStyles = makeStyles(theme => ({
  sidebar: {
    width: "calc(100vh - 60px)",
    height: 100,
    transformOrigin: "top left",
    transform: "rotate(-90deg)",
    position: "fixed",
    left: 0,
    top: "100%",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    flexDirection: "row-reverse",
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  link: {
    textDecoration: "none",
    color: theme.palette.primary.alt,
    cursor: "pointer",
    position: "relative"
  },
  activeLink: {
    fontWeight: 600,
    color: theme.palette.primary.main,
    "&:before": {
      content: "'▼'",
      position: "absolute",
      top: theme.spacing(-3),
      left: "50%",
      transform: "translateX(-50%)"
    }
  }
}));

const Sidebar = () => {
  const classes = useStyles();

  return (
    <nav className={classes.sidebar}>
      {sections.map(section => (
        <Link
          key={section}
          to={section.toLowerCase().split(" ").join("-")}
          className={classes.link}
          spy={true}
          offset={-72}
          exact="true"
          activeClass={classes.activeLink}
        >
          {section}
        </Link>
      ))}
    </nav>
  );
};

export default Sidebar;
