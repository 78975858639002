import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import PlaylistPlayIcon from "@material-ui/icons/PlaylistPlay";

import { togglePlaylistDrawer } from "Slices/openSlice";
import { playlistSelector } from "Slices/playlistSlice";

const useStyles = makeStyles(theme => ({
  button: {
    position: "absolute",
    border: "none",
    outline: "none",
    right: 0,
    width: 250,
    height: "100%",
    borderRadius: 0,
    boxShadow: "none",
    [theme.breakpoints.down("md")]: {
      width: 50
    }
  }
}));

const PlaylistButton = () => {
  const classes = useStyles();
  const { playlist } = useSelector(playlistSelector);
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(togglePlaylistDrawer());
  };

  return (
    <Button
      className={classes.button}
      variant="contained"
      color="primary"
      onClick={handleClick}
    >
      <Hidden lgUp>
        <PlaylistPlayIcon fontSize="large" />
      </Hidden>

      <Hidden mdDown>My Playlist ({playlist.length})</Hidden>
    </Button>
  );
};

export default PlaylistButton;
