import React, { forwardRef, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import InputAdornment from "@material-ui/core/InputAdornment";
import Slide from "@material-ui/core/Slide";
import TextField from "@material-ui/core/TextField";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import BusinessOutlinedIcon from "@material-ui/icons/BusinessOutlined";
import ChevronLeftRoundedIcon from "@material-ui/icons/ChevronLeftRounded";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import PersonOutlinedIcon from "@material-ui/icons/PersonOutlined";

import {
  openSelector,
  toggleLoginDialog,
  toggleRegisterDialog,
  toggleSnackbar
} from "Slices/openSlice";
import { registerUser, userSelector, clearErrors } from "Slices/userSlice";
import { setSnackbarMessage } from "Slices/contentSlice";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(5, 10),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(4, 2)
    }
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: 36,
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      marginTop: 0
    }
  },
  textField: {
    margin: theme.spacing(1, 0),
    "& input": {
      fontSize: 14
    }
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  button: {
    fontSize: 18,
    fontWeight: "bold",
    color: theme.palette.primary.main,
    textTransform: "none"
  },
  backButton: {
    position: "absolute",
    top: 0,
    left: 0,
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  backIcon: {
    fontSize: "40px !important"
  },
  disclaimer: {
    fontSize: 12,
    color: theme.palette.primary.main
  }
}));

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SignUpDialog = () => {
  const classes = useStyles();
  const mdUp = useMediaQuery(theme => theme.breakpoints.up("md"));
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors: feErrors }
  } = useForm();
  const { registerDialog } = useSelector(openSelector);
  const { errors } = useSelector(userSelector);
  const dispatch = useDispatch();
  const password = useRef({});
  password.current = watch("password", "");

  const onSubmit = data => {
    dispatch(registerUser(data)).then(res => {
      if (!res.error) {
        handleClose();
        dispatch(setSnackbarMessage("Sign up successful!"));
        dispatch(toggleSnackbar());
      }
    });
  };

  const handleClick = () => {
    handleClose();

    const timeout = setTimeout(() => {
      dispatch(toggleLoginDialog());
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  };

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }

    dispatch(toggleRegisterDialog());
    dispatch(clearErrors());
    reset();
  };

  return (
    <Dialog
      open={registerDialog}
      TransitionComponent={Transition}
      keepMounted
      fullWidth
      onClose={handleClose}
    >
      <div className={classes.root}>
        <Button
          className={classes.backButton}
          startIcon={<ChevronLeftRoundedIcon className={classes.backIcon} />}
          color="primary"
          disableRipple
          onClick={handleClick}
        >
          Back
        </Button>

        <h1 className={classes.title}>Create an account</h1>

        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <Grid container spacing={mdUp ? 3 : 0}>
            <Grid item xs={12} md={6}>
              <TextField
                type="text"
                placeholder="Enter name"
                fullWidth
                color="primary"
                error={errors?.hasOwnProperty("name")}
                helperText={errors?.name}
                className={classes.textField}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonOutlinedIcon className={classes.icon} />
                    </InputAdornment>
                  )
                }}
                {...register("name")}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                type="text"
                placeholder="Enter company (optional)"
                fullWidth
                color="primary"
                error={errors?.hasOwnProperty("companyName")}
                helperText={errors?.companyName}
                className={classes.textField}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <BusinessOutlinedIcon className={classes.icon} />
                    </InputAdornment>
                  )
                }}
                {...register("companyName")}
              />
            </Grid>
          </Grid>

          <TextField
            type="text"
            placeholder="Enter email*"
            fullWidth
            color="primary"
            error={errors?.hasOwnProperty("email")}
            helperText={errors?.email}
            className={classes.textField}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailOutlinedIcon className={classes.icon} />
                </InputAdornment>
              )
            }}
            {...register("email")}
          />

          <TextField
            type="password"
            placeholder="Enter password"
            fullWidth
            color="primary"
            error={errors?.hasOwnProperty("password")}
            helperText={errors?.password}
            className={classes.textField}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockOutlinedIcon className={classes.icon} />
                </InputAdornment>
              )
            }}
            {...register("password")}
          />

          <TextField
            type="password"
            placeholder="Re-enter password"
            fullWidth
            color="primary"
            error={feErrors?.hasOwnProperty("confirmPassword")}
            helperText={feErrors?.confirmPassword?.message}
            className={classes.textField}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockOutlinedIcon className={classes.icon} />
                </InputAdornment>
              )
            }}
            {...register("confirmPassword", {
              validate: value =>
                value === password.current || "Passwords do not match"
            })}
          />

          <p className={classes.disclaimer}>
            *We request that you register with your work email and have an
            identifiable name. This is a private website by invitation only. We
            reserve the right to remove any users from this website at any time.
          </p>

          <Button
            variant="outlined"
            type="submit"
            fullWidth
            className={classes.button}
          >
            Sign Up
          </Button>
        </form>
      </div>
    </Dialog>
  );
};

export default SignUpDialog;
