import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles(theme => ({
    root: {
      color: theme.palette.common.white,
      objectFit: "contain",
      backgroundSize: "cover",
      backgroundPosition: "center top",
      position: "relative",
      margin: theme.spacing(4, 8, 0),
      [theme.breakpoints.down("sm")]: {
        margin: theme.spacing(2, 2, 0)
      }
    },
    content: {
      color: theme.palette.primary.main,
      marginBottom: theme.spacing(7),
      [theme.breakpoints.down("sm")]: {
        marginBottom: theme.spacing(4)
      }
    },
    companyName: {
      fontSize: 40,
      textAlign: "center",
      textTransform: "uppercase",
      [theme.breakpoints.down("sm")]: {
        fontSize: 32
      }
    },
    description: {
      fontSize: 20,
      [theme.breakpoints.down("sm")]: {
        fontSize: 16
      }
    },
    flexbox: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      flexWrap: "wrap"
    },
    bannerImg: {
      width: "100%"
    }
  }));

const Blurb = () => {
    const classes = useStyles();

    return(
        <div className={classes.root}>
            <div className={classes.content}>
              <img className={classes.bannerImg} src="https://res.cloudinary.com/startsomething-com-sg/image/upload/v1701072817/wavemaker/eitxcixgcgjn1naxm0bn.jpg"></img>
              <p className={classes.description}>#Wavemakers is Wavemaker Partners’ annual Startup Showcase where select portfolio companies present their game-changing tech solutions in front of investors, corporates, government and media representatives, and other partners in the Wavemaker ecosystem. Wavemaker is Southeast Asia’s leading VC firm focusing on the early-stage enterprise, deep tech, and sustainability spaces, with 190+ startups backed since 2012. The firm has over US$300 million in AUM and an aggregate exit value of US$1.8 billion.</p>
              <div className="divider-container">
                <Divider className="divider" light />
              </div>
            </div>     
        </div>
    )
}

export default Blurb;